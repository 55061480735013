import AccountBoxIcon from '@material-ui/icons/AccountBox'
import ChromeReaderMode from '@material-ui/icons/ChromeReaderMode'
import DaschboardIcon from '@material-ui/icons/Dashboard'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import GetApp from '@material-ui/icons/GetApp'
import LanguageIcon from '@material-ui/icons/Language'
import LockIcon from '@material-ui/icons/Lock'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'
import React from 'react'
import SettingsIcon from '@material-ui/icons/SettingsApplications'
import StyleIcon from '@material-ui/icons/Style'
import allLocales from './locales'
import allThemes from './themes'

import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalParkingIcon from '@material-ui/icons/LocalParking';
import PersonIcon from '@material-ui/icons/Person';
import PermDataSettingIcon from '@material-ui/icons/PermDataSetting';
import GroupAddIcon from '@material-ui/icons/GroupAdd';

import * as Constants from '../services/dbConnection'

const getMenuItems = (props) => {
  const {
    intl,
    updateLocale,
    locale,
    menuContext,
    themeContext,
    a2HSContext,
    auth: authData,
  } = props
  const { isDesktop, isAuthMenuOpen, useMiniMode, setMiniMode } = menuContext
  const { themeID, setThemeID } = themeContext
  const { auth, setAuth } = authData
  const { isAppInstallable, isAppInstalled, deferredPrompt } = a2HSContext

  const localeItems = allLocales.map((l) => {
      // auth.menu = '12345678'
      // console.log("Auth menu ", auth.menu, '1 ->', auth.menu.substring(0,1))  - Home
      // console.log("Auth menu ", auth.menu, '2 ->', auth.menu.substring(1,2))  - Master Tables
      // console.log("Auth menu ", auth.menu, '3 ->', auth.menu.substring(2,3))  - Orders
      // console.log("Auth menu ", auth.menu, '4 ->', auth.menu.substring(3,4))  
      // console.log("Auth menu ", auth.menu, '5 ->', auth.menu.substring(4,5))  - Promotions
      // console.log("Auth menu ", auth.menu, '6 ->', auth.menu.substring(5,6))
      // console.log("Auth menu ", auth.menu, '7 ->', auth.menu.substring(6,7))
      // console.log("Auth menu ", auth.menu, '8 ->', auth.menu.substring(7,8))
      // console.log("isDesktop", isDesktop)
      // console.log("isAuthMenuOpen", isAuthMenuOpen)


    return {
      value: undefined,
      visible: true,
      primaryText: intl.formatMessage({ id: l.locale }),
      onClick: () => {
        updateLocale(l.locale)
      },
      leftIcon: <LanguageIcon />,
    }
  })

  const isAuthorised = auth.isAuthenticated

  const themeItems = allThemes.map((t) => {
    return {
      value: undefined,
      visible: true,
      primaryText: intl.formatMessage({ id: t.id }),
      onClick: () => {
        setThemeID(t.id)
      },
      leftIcon: <StyleIcon style={{ color: t.color }} />,
    }
  })

  if (isAuthMenuOpen || !isAuthorised) {
    return [
      {
        value: '/my_account',
        primaryText: intl.formatMessage({
          id: 'my_account',
          defaultMessage: 'My Account',
        }),
        leftIcon: <AccountBoxIcon />,
      },
      {
        value: '/signin',
        onClick: isAuthorised
          ? () => {
              setAuth({ isAuthenticated: false })
            }
          : () => {},
        visible: true,
        primaryText: isAuthorised
          ? intl.formatMessage({ id: 'sign_out' })
          : intl.formatMessage({ id: 'sign_in' }),
        leftIcon: isAuthorised ? <ExitToAppIcon /> : <LockIcon />,
      },
      {
        value: undefined,
        visible: true,
        onClick: () => {

          const UGURL =  Constants.URL + "/SFA_UserGuide.pdf"

          const httpHeaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Access-Control-Allow-Headers': 'Authorization',
            // 'Authorization': `Basic ${Constants.SSRS}`
          }
        
          const requestOptions = {
            method: 'GET',
            headers: httpHeaders,
            // body: httpBody
          };
        
          fetch(UGURL, requestOptions)
            .then(result => {
              console.log("data from download site", UGURL)
              result.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = "Sfa_Portal_Guide.pdf";
                a.click();
              })
            })
            .catch(rejected => {
              console.log("Rejected ", rejected);
            });
       },
        primaryText: intl.formatMessage({
          id: 'User Guide',
          defaultMessage: 'User Guide',
        }),
        leftIcon: <GetApp />,
      },
    ]
  }
  return [
    {
      value: '/home',
      visible: isAuthorised && (auth.menu.substring(0,1) !== '0'),
      primaryText: intl.formatMessage({ id: 'home' }),
      leftIcon: <DaschboardIcon />,
    },
    { 
      value: '/pageOrder',
      visible: isAuthorised && (auth.menu.substring(2,3) === '1'),
      primaryText: intl.formatMessage({ id: 'pageOrders' }),
      leftIcon: <ShoppingCartIcon />,
    },
    {
      value: '/pageCustomer',
      visible: isAuthorised && (auth.menu.substring(1,2) === '1'),
      primaryText: intl.formatMessage({ id: 'pageCustomer' }),
      leftIcon: <PeopleIcon />,
    },
    { 
      value: '/pageMaterial',
      visible: isAuthorised  && (auth.menu.substring(1,2) === '1'),
      primaryText: intl.formatMessage({ id: 'pageMaterial' }),
      leftIcon: <LocalParkingIcon />,
    },
    { 
      value: '/pageSalesman',
      visible: isAuthorised  && (auth.menu.substring(1,2) === '1'),
      primaryText: intl.formatMessage({ id: 'pageSalesman' }),
      leftIcon: <PersonIcon />,
    },
    { 
      value: '/pageUDC',
      visible: isAuthorised  && (auth.menu.substring(1,2) === '1'),
      primaryText: intl.formatMessage({ id: 'pageUDC' }),
      leftIcon: <PermDataSettingIcon />,
    },
    {
      value: '/pagePromotions',
      visible: isAuthorised && (auth.menu.substring(4,5) === '1'),
      primaryText: intl.formatMessage({ id: 'pagePromotions' }),
      leftIcon: <LocalOfferIcon />,
    },
    { divider: true },
    {
      value: '/pageUsers',
      visible: isAuthorised && (auth.menu.substring(0,1) === '9'),
      primaryText: intl.formatMessage({ id: 'pageUsers' }),
      leftIcon: <GroupAddIcon />,
    },
    { divider: true },
    {
      primaryText: intl.formatMessage({ id: 'settings' }),
      visible: isAuthorised && (auth.menu.substring(0,1) === '9'),
      primaryTogglesNestedList: true,
      leftIcon: <SettingsIcon />,
      nestedItems: [
        {
          primaryText: intl.formatMessage({ id: 'theme' }),
          secondaryText: intl.formatMessage({ id: themeID }),
          primaryTogglesNestedList: true,
          leftIcon: <StyleIcon />,
          nestedItems: themeItems,
        },
        {
          primaryText: intl.formatMessage({ id: 'language' }),
          secondaryText: intl.formatMessage({ id: locale }),
          primaryTogglesNestedList: true,
          leftIcon: <LanguageIcon />,
          nestedItems: localeItems,
        },
        {
          visible: isDesktop ? true : false,
          onClick: () => {
            setMiniMode(!useMiniMode)
          },
          primaryText: intl.formatMessage({
            id: 'menu_mini_mode',
          }),
          leftIcon: useMiniMode ? <MenuOpenIcon /> : <ChromeReaderMode />,
        },
      ],
    },
    { divider: true },
    {
      value: null,
      visible: isAppInstallable && !isAppInstalled,
      onClick: () => {
        deferredPrompt.prompt()
      },
      primaryText: intl.formatMessage({
        id: 'install',
        defaultMessage: 'Install',
      }),
      leftIcon: <GetApp />,
    },
  ]
}
export default getMenuItems
