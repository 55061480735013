
// export const URL = 'http://localhost:3009'
export const URL = 'http://pd.g-supreme.com.my'
export const WSURL = 'http://pd.g-supreme.com.my:3009'
export const SSRSURL_ENV = ''
export const IMGURL = 'url(https://source.unsplash.com/user/llc2668/likes)'

// export const WSURL = 'http://localhost:3333'

// export const URL = 'http://pd.g-supreme.com.my:8080'
// export const WSURL = 'http://pd.g-supreme.com.my:3333'
// export const SSRSURL_ENV = '_Test'
// export const IMGURL = 'url(https://source.unsplash.com/1600x900/?nature)'

export const SSRSURL = 'http://pd.g-supreme.com.my/reportserver/report/Pages/ReportViewer.aspx?'
export const SSRS = 'bGltOmN3MjEwNkBMMQ=='

