import React, { lazy } from 'react'
import AuthorizedRoute from 'base-shell/lib/components/AuthorizedRoute/AuthorizedRoute'
import UnauthorizedRoute from 'base-shell/lib/components/UnauthorizedRoute/UnauthorizedRoute'

const SignIn = lazy(() => import('../pages/SignIn/SignIn'))
const PasswordReset = lazy(() => import('../pages/PasswordReset/PasswordReset'))
const Home = lazy(() => import('../pages/Home/Home'))

const MyAccount = lazy(() => import('../pages/MyAccount/MyAccount'))
const PageCustomerRoute = lazy(() => import('../pages/PageCstRoute/JourneyPlan'))
const PageCustomers = lazy(() => import('../pages/PageCst/PageCst.js'))
const PageMaterials = lazy(() => import('../pages/PageMaterial/Material.js'))
const PageSalesmans = lazy(() => import('../pages/PageSalesman/Salesman.js'))
const PageUsers = lazy(() => import('../pages/PageUser/User.js'))
const PageUDC = lazy(() => import('../pages/PageUDC/UDC.js'))
const PageCustomerDetails = lazy(() => import('../pages/PageCst/CustomerDetails.js'))
const PageOrder = lazy(() => import('../pages/PageOrder/Orders.js'))
const PagePromotions = lazy(() => import('../pages/PagePromotion/Promotions.js'))
const PagePromoDtl = lazy(() => import('../pages/PagePromotion/PromoDtl.js'))

const routes = [
  <UnauthorizedRoute path="/signin" redirectTo="/" exact component={SignIn} />,
  <UnauthorizedRoute path="/password_reset" redirectTo="/" exact component={PasswordReset}/>,
  <AuthorizedRoute path="/my_account" exact component={MyAccount} />,
  <AuthorizedRoute path="/home" exact component={Home} />,
  <AuthorizedRoute path="/pageCustomerRoute" exact component={PageCustomerRoute} />,
  <AuthorizedRoute path="/pageCustomer" exact component={PageCustomers} />,
  <AuthorizedRoute path="/pageMaterial" exact component={PageMaterials} />,
  <AuthorizedRoute path="/pageSalesman" exact component={PageSalesmans} />,
  <AuthorizedRoute path="/pageUDC" exact component={PageUDC} />,
  <AuthorizedRoute path="/pageUsers" exact component={PageUsers} />,
  <AuthorizedRoute path="/pageCustomerDetails" exact component={PageCustomerDetails} />,
  <AuthorizedRoute path="/pageOrder" exact component={PageOrder} />,
  <AuthorizedRoute path="/pagePromotions" exact component={PagePromotions} />,
  <AuthorizedRoute path="/pagePromoDtl" exact component={PagePromoDtl} />,
]

export default routes
